jQuery(
    function ($) {
        var scrolltop;
        var scrollwindow;
        var backgroundscroll;

        $(window).scroll(
            function () {
                scrolltop = $(window).scrollTop();
                scrollwindow = scrolltop;

                //Hero-header
                //$(".hero-accueil #slideshow").css("top", scrolltop / 6 + "px");

                $(" .hero-banner").css(
                    "backgroundPositionY",
                    "calc(50% + " + scrolltop / 12 + "px)"
                );
                $(" .hero-banner img").css(
                    {
                        transform: "translateY(" + scrolltop / 12 + "px)",
                    }
                );

                // $(".page .content .entry-header img").css("top", scrolltop / 6 + "px");

                // Section Below Header
                $(".parallax").css("backgroundPositionY", -(scrolltop / 6) + "px");

                // Section Above Footer
                if (0 !== $(".layout-hero").length 
                    && scrollwindow > $(".layout-hero ").offset().top
                ) {
                      backgroundscroll = scrollwindow - $(".layout-hero").offset().top;
                      $(".layout-hero img").css("top", backgroundscroll / 6 + "px");
                    $(".layout-hero .entry-meta").css(
                        "opacity",
                        ($(window).height() - scrollwindow * 2) / $(window).height()
                    );
                }
            }
        );
    }
);
